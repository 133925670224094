const actions = {
  SET_STATE: 'resume/SET_STATE',
  GET: 'resume/GET',
  // ADD: 'resume/ADD',
  UPDATE: 'resume/UPDATE',
  DELETE: 'resume/DELETE',
  UPDATE_IMAGE: 'resume/UPDATE_IMAGE',
  UPDATE_EMPLOYMENT: 'resume/UPDATE_EMPLOYMENT',
  ADD_EDUCATION: 'resume/ADD_EDUCATION',
  UPDATE_EDUCATION: 'resume/UPDATE_EDUCATION',
  DELETE_EDUCATION: 'resume/DELETE_EDUCATION',
  ADD_LANGUAGE: 'resume/ADD_LANGUAGE',
  UPDATE_LANGUAGE: 'resume/UPDATE_LANGUAGE',
  DELETE_LANGUAGE: 'resume/DELETE_LANGUAGE',
  ADD_SKILL: 'resume/ADD_SKILL',
  UPDATE_SKILL: 'resume/UPDATE_SKILL',
  DELETE_SKILL: 'resume/DELETE_SKILL',
  ADD_WORK: 'resume/ADD_WORK',
  UPDATE_WORK: 'resume/UPDATE_WORK',
  DELETE_WORK: 'resume/DELETE_WORK',
}

export default actions
