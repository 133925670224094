/* eslint-disable camelcase */
import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import * as resume from 'services/resume'
import actions from './actions'

const getIndustry = (state, id) => {
  return state.industries.list?.find(item => item.id === id)
}

const getEmploymentType = (state, value) => {
  return state.employmentTypes.list?.find(item => item.value === value)
}

const getAvailablity = (state, value) => {
  return state.availablities.list?.find(item => item.value === value)
}

const getGeographicMobility = (state, value) => {
  return state.geographicMobilities.list?.find(item => item.value === value)
}

const getApplicantStatus = (state, value) => {
  return state.applicantStatuses.list?.find(item => item.value === value)
}

const getSalaryRange = (state, id) => {
  return state.salaryRanges.list?.find(item => item.id === id)
}

const getStudyTypes = state => {
  return state.studyTypes.list
}

// Resume
export function* GET() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      info: null,
    },
  })

  const response = yield call(resume.getResume)

  const studyTypes = yield select(getStudyTypes)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        info: {
          ...response,
          employment: {
            ...response.employment,
            availablity: yield select(getAvailablity, response.employment?.availablity),
            desired_salary: yield select(getSalaryRange, response.employment?.desired_salary),
            employment_type: yield select(getEmploymentType, response.employment?.employment_type),
            industry: yield select(getIndustry, response.employment?.industry),
            geographic_mobility: yield select(
              getGeographicMobility,
              response.employment?.geographic_mobility,
            ),
            status: yield select(getApplicantStatus, response.employment?.status),
          },
          educations: response.educations?.map(education => {
            return {
              ...education,
              study_type: studyTypes.find(study => study.id === education.study_type),
            }
          }),
        },
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.updateResume, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Votre CV a été modifié avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.deleteResume)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Votre CV a été suprimé avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

// Image
export function* UPDATE_IMAGE({ payload }) {
  const { values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.updateImage, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Image modifiée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

// Employment
export function* UPDATE_EMPLOYMENT({ payload }) {
  const { values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.updateEmployment, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Poste souhaité modifiée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

// Educations
export function* ADD_EDUCATION({ payload }) {
  const { values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.addEducation, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Education ajoutée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_EDUCATION({ payload }) {
  const { id, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.updateEducation, id, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Education modifiée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE_EDUCATION({ payload }) {
  const { id } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.deleteEducation, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Education suprimée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

// Languages
export function* ADD_LANGUAGE({ payload }) {
  const { values } = payload
  const { language, fluency } = values
  if (language === '' || fluency === '') {
    notification.error({
      message: 'Erreur',
      description: 'Les champs ne peuvent pas être vides',
    })
    return
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.addLanguage, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Langue ajoutée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_LANGUAGE({ payload }) {
  const { id, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.updateLanguage, id, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Langue modifiée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE_LANGUAGE({ payload }) {
  const { id } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.deleteLanguage, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Langue suprimée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

// Skills
export function* ADD_SKILL({ payload }) {
  const { values } = payload
  const { name, level } = values
  if (name === '' || level === '') {
    notification.error({
      message: 'Erreur',
      description: 'Les champs ne peuvent pas être vides',
    })
    return
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.addSkill, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Compétence ajoutée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_SKILL({ payload }) {
  const { id, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.updateSkill, id, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Compétence modifiée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE_SKILL({ payload }) {
  const { id } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.deleteSkill, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Compétence suprimée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

// Works
export function* ADD_WORK({ payload }) {
  const { values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.addWork, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Expérience ajoutée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE_WORK({ payload }) {
  const { id, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.updateWork, id, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Expérience modifiée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE_WORK({ payload }) {
  const { id } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(resume.deleteWork, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: 'Expérience suprimée avec succès',
    })

    yield put({ type: actions.GET })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET, GET),
    // takeEvery(actions.ADD, ADD),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.UPDATE_IMAGE, UPDATE_IMAGE),
    takeEvery(actions.UPDATE_EMPLOYMENT, UPDATE_EMPLOYMENT),
    takeEvery(actions.ADD_EDUCATION, ADD_EDUCATION),
    takeEvery(actions.UPDATE_EDUCATION, UPDATE_EDUCATION),
    takeEvery(actions.DELETE_EDUCATION, DELETE_EDUCATION),
    takeEvery(actions.ADD_LANGUAGE, ADD_LANGUAGE),
    takeEvery(actions.UPDATE_LANGUAGE, UPDATE_LANGUAGE),
    takeEvery(actions.DELETE_LANGUAGE, DELETE_LANGUAGE),
    takeEvery(actions.ADD_SKILL, ADD_SKILL),
    takeEvery(actions.UPDATE_SKILL, UPDATE_SKILL),
    takeEvery(actions.DELETE_SKILL, DELETE_SKILL),
    takeEvery(actions.ADD_WORK, ADD_WORK),
    takeEvery(actions.UPDATE_WORK, UPDATE_WORK),
    takeEvery(actions.DELETE_WORK, DELETE_WORK),
  ])
}
