const actions = {
  SET_STATE: 'careers/SET_STATE',
  GET_ALL: 'careers/GET_ALL',
  // GET: 'careers/GET',
  // ADD: 'careers/ADD',
  // UPDATE: 'careers/UPDATE',
  // DELETE: 'careers/DELETE',
}

export default actions
