import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import applications from './applications/sagas'
import occupations from './occupations/sagas'
import industries from './industries/sagas'
import educations from './educations/sagas'
import careers from './careers/sagas'
import languages from './languages/sagas'
import employmentTypes from './employmentTypes/sagas'
import legalForms from './legalForms/sagas'
import countries from './countries/sagas'
import profile from './profile/sagas'
import jobStates from './jobStates/sagas'
import civilities from './civilities/sagas'
import resume from './resume/sagas'
import studyTypes from './studyTypes/sagas'
import applicantStatuses from './applicantStatuses/sagas'
import availablities from './availablities/sagas'
import geographicMobilities from './geographicMobilities/sagas'
import salaryRanges from './salaryRanges/sagas'
import alerts from './alerts/sagas'
import attachments from './attachments/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    applications(),
    occupations(),
    industries(),
    educations(),
    careers(),
    languages(),
    employmentTypes(),
    legalForms(),
    profile(),
    countries(),
    jobStates(),
    civilities(),
    studyTypes(),
    applicantStatuses(),
    availablities(),
    geographicMobilities(),
    salaryRanges(),
    resume(),
    alerts(),
    attachments(),
  ])
}
