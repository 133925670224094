import axios from 'axios'
import store from 'store'
import { history } from 'index'

const apiClient = axios.create({
  // eslint-disable-next-line no-underscore-dangle
  baseURL: window._env_.API_URL,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers['X-Token'] = accessToken
    request.headers.Accept = 'application/json'
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  const {
    response: { data, status },
  } = error

  const isAuth = window.location.pathname.split('/')[1] === 'auth'

  if (status === 401 && !isAuth) {
    history.push('/auth/login')
  }

  return Promise.reject(data)
})

export default apiClient
