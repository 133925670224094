const actions = {
  SET_STATE: 'educations/SET_STATE',
  GET_ALL: 'educations/GET_ALL',
  // GET: 'educations/GET',
  // ADD: 'educations/ADD',
  // UPDATE: 'educations/UPDATE',
  // DELETE: 'educations/DELETE',
}

export default actions
