const actions = {
  SET_STATE: 'civilities/SET_STATE',
  GET_ALL: 'civilities/GET_ALL',
  // GET: 'civilities/GET',
  // ADD: 'civilities/ADD',
  // UPDATE: 'civilities/UPDATE',
  // DELETE: 'civilities/DELETE',
}

export default actions
