import apiClient from 'services/axios'

export async function get(id) {
  return apiClient
    .get(`/attachments/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function getAll(resumeId) {
  return apiClient
    .get(`/resumes/${resumeId}/attachments`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function remove(resumeId, attachmentId) {
  return apiClient
    .delete(`/resumes/${resumeId}/attachments/${attachmentId}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
