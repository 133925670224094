const actions = {
  SET_STATE: 'occupations/SET_STATE',
  GET_ALL: 'occupations/GET_ALL',
  // GET: 'occupations/GET',
  // ADD: 'occupations/ADD',
  // UPDATE: 'occupations/UPDATE',
  // DELETE: 'occupations/DELETE',
}

export default actions
