import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as attachments from 'services/attachments'
import { notification } from 'antd'
import actions from './actions'

export function* GET({ payload }) {
  const { id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
      single: null,
    },
  })

  const response = yield call(attachments.get, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        single: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_ALL({ payload }) {
  const { resumeId } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const response = yield call(attachments.getAll, resumeId)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { resumeId, attachmentId } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(attachments.remove, resumeId, attachmentId)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Fichier supprimé',
      // description: 'Expérience suprimée avec succès',
    })

    yield put({
      type: actions.GET_ALL,
      payload: { resumeId },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET, GET),
    takeEvery(actions.GET_ALL, GET_ALL),
    takeEvery(actions.DELETE, DELETE),
  ])
}
