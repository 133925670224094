const actions = {
  SET_STATE: 'countries/SET_STATE',
  GET_ALL: 'countries/GET_ALL',
  // GET: 'countries/GET',
  // ADD: 'countries/ADD',
  // UPDATE: 'countries/UPDATE',
  // DELETE: 'countries/DELETE',
}

export default actions
