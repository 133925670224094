import moment from 'moment'
import apiClient from 'services/axios'

// Resume
export async function getResume() {
  return apiClient
    .get('/resumes')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateResume(data) {
  return apiClient
    .put('/resumes', {
      email: data.email,
      label: data.label,
      phone: data.phone,
      resume: data.resume,
      date_of_birth: moment(data.date_of_birth).format('YYYY-MM-DD'),
      driver_licence: data.driver_licence,
      gender: data.gender,
      geographic_mobility: data.geographic_mobility,
      image: data.image,
      attachment: data.attachment,
      location: data.location,
      marital_status: data.marital_status,
      military_service: data.military_service,
      passport: data.passport,
      summary: data.summary,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteResume() {
  return apiClient
    .delete(`/resumes`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

// Image
export async function updateImage(data) {
  return apiClient
    .put('/resumes/image', {
      image: data.image,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

// Employment
export async function updateEmployment(data) {
  return apiClient
    .put('/resumes/employment', {
      availablity: data.availablity,
      desired_salary: data.desired_salary,
      employment_type: data.employment_type,
      geographic_mobility: data.geographic_mobility,
      industry: data.industry,
      status: data.status,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

// Educations
export async function addEducation(data) {
  return apiClient
    .post('/resumes/educations', {
      area: data.area,
      courses: data.courses,
      current_education: data.current_education,
      institution: data.institution,
      score: data.score,
      study_type: data.study_type,
      start_date: moment(data.start_date).format('YYYY-MM-DD'),
      end_date: data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : undefined,
      summary: data.summary,
      url: data.url,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateEducation(id, data) {
  return apiClient
    .put(`/resumes/educations/${id}`, {
      area: data.area,
      courses: data.courses,
      current_education: data.current_education,
      institution: data.institution,
      score: data.score,
      study_type: data.study_type,
      start_date: moment(data.start_date).format('YYYY-MM-DD'),
      end_date: data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : undefined,
      summary: data.summary,
      url: data.url,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteEducation(id) {
  return apiClient
    .delete(`/resumes/educations/${id}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

// Languages
export async function addLanguage(data) {
  return apiClient
    .post('/resumes/languages', {
      fluency: data.fluency,
      language: data.language,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateLanguage(id, data) {
  return apiClient
    .put(`/resumes/languages/${id}`, {
      fluency: data.fluency,
      language: data.language,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteLanguage(id) {
  return apiClient
    .delete(`/resumes/languages/${id}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

// Skills
export async function addSkill(data) {
  return apiClient
    .post('/resumes/skills', {
      name: data.name,
      level: data.level,
      keywords: data.keywords,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateSkill(id, data) {
  return apiClient
    .put(`/resumes/skills/${id}`, {
      name: data.name,
      level: data.level,
      keywords: data.keywords,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteSkill(id) {
  return apiClient
    .delete(`/resumes/skills/${id}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

// Works
export async function addWork(data) {
  return apiClient
    .post('/resumes/works', {
      current_position: data.current_position,
      name: data.name,
      position: data.position,
      summary: data.summary,
      industry: data.industry,
      location: data.location,
      number_of_employees: data.number_of_employees,
      occupation: data.occupation,
      highlight: data.highlight,
      url: data.url,
      start_date: moment(data.start_date).format('YYYY-MM-DD'),
      end_date: data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : undefined,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateWork(id, data) {
  return apiClient
    .put(`/resumes/works/${id}`, {
      current_position: data.current_position,
      name: data.name,
      position: data.position,
      summary: data.summary,
      industry: data.industry,
      location: data.location,
      number_of_employees: data.number_of_employees,
      occupation: data.occupation,
      highlight: data.highlight,
      url: data.url,
      start_date: moment(data.start_date).format('YYYY-MM-DD'),
      end_date: data.end_date ? moment(data.end_date).format('YYYY-MM-DD') : undefined,
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteWork(id) {
  return apiClient
    .delete(`/resumes/works/${id}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
