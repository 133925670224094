import jwt from 'jsonwebtoken'
import Cookies from 'universal-cookie'
import apiClient from 'services/axios'
import store from 'store'

const cookies = new Cookies()

export async function login(email, password) {
  return apiClient
    .post('/authentication/signin', {
      email,
      password,
    })
    .then(response => {
      if (response && response.status === 200) {
        const accessToken = response.headers['x-token']
        const decodedToken = jwt.decode(accessToken)

        if (decodedToken.user.roles !== 'system_jobseeker') {
          return false
        }

        if (accessToken) {
          store.set('accessToken', accessToken)
          // eslint-disable-next-line no-underscore-dangle
          cookies.set('accessToken', accessToken, { domain: window._env_.PUBLIC_DOMAIN, path: '/' })
        }
        // return response.
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(data) {
  return apiClient
    .post('/authentication/signup', {
      ...data,
      first_name: data.firstName,
      last_name: data.lastName,
      // role: 'system_jobseeker',
    })
    .then(response => {
      if (response && response.status === 201) {
        return true
      }
      return false
    })
    .catch(err => {
      const errors = {}
      err.errors.forEach(error => {
        errors[error.name] = error.reason
      })
      return { errors }
    })
}

export async function currentAccount() {
  return apiClient
    .get('/me')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/authentication/logout')
    .then(() => {
      store.remove('accessToken')
      cookies.remove('accessToken')
      // eslint-disable-next-line no-underscore-dangle
      cookies.remove('accessToken', { domain: window._env_.PUBLIC_DOMAIN, path: '/' })

      // eslint-disable-next-line no-underscore-dangle
      window.location.replace(window._env_.PUBLIC_SITE)
      return true
    })
    .catch(err => console.log(err))
}

export async function forgot(data) {
  return apiClient
    .post('/authentication/forgot', {
      email: data.email,
    })
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function verifyToken(email, token) {
  return apiClient
    .get(`/authentication/reset?email=${email}&token=${token}`)
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function reset(token, password, confirmPassword) {
  return apiClient
    .post('/authentication/reset', {
      token,
      password,
      confirm_password: confirmPassword,
    })
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function completeRegistration(email, token) {
  return apiClient
    .post('/authentication/complete', {
      token,
      email,
    })
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
