import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import * as alerts from 'services/alerts'
import actions from './actions'

const getIndustries = state => {
  return state.industries.list
}

const getEmploymentTypes = state => {
  return state.employmentTypes.list
}

const getCareerLevels = state => {
  return state.careers.list
}

const getOccupations = state => {
  return state.occupations.list
}

export function* GET_ALL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
      single: null,
    },
  })

  const { pagination = { current: 1, pageSize: 9999 } } = payload
  const { current, pageSize } = pagination
  const limit = pageSize
  const offset = (current - 1) * pageSize

  const response = yield call(alerts.getAll, limit, offset)

  if (response) {
    const industriesList = yield select(getIndustries)
    const employmentTypesList = yield select(getEmploymentTypes)
    const careerLevelsList = yield select(getCareerLevels)
    const occupationsList = yield select(getOccupations)

    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        total: response.length,
        single: null,
        list: response.list.map(alert => {
          const { conditions } = JSON.parse(alert.query)

          const conditionsObject = {}
          let conditionValue = null

          conditions.forEach(condition => {
            const items = []
            // eslint-disable-next-line default-case
            switch (condition.field) {
              case 'title':
              case 'jobs_locations.city':
                // eslint-disable-next-line prefer-destructuring
                conditionValue = condition.value
                break

              case 'occupational_category':
                condition.value.split(',').forEach(id => {
                  const occupation = occupationsList.find(item => item.id === parseInt(id, 10))
                  if (occupation) {
                    items.push(occupation)
                  }
                })

                conditionValue = items
                break

              case 'employment_type':
                condition.value.split(',').forEach(value => {
                  const employmentType = employmentTypesList.find(item => item.value === value)
                  if (employmentType) {
                    items.push(employmentType)
                  }
                })

                conditionValue = items
                break

              case 'career_level':
                condition.value.split(',').forEach(id => {
                  const careerLevel = careerLevelsList.find(item => item.id === parseInt(id, 10))
                  if (careerLevel) {
                    items.push(careerLevel)
                  }
                })

                conditionValue = items
                break

              case 'jobs.industry':
                condition.value.split(',').forEach(id => {
                  const industry = industriesList.find(item => item.id === parseInt(id, 10))
                  if (industry) {
                    items.push(industry)
                  }
                })

                conditionValue = items
                break
            }

            conditionsObject[condition.field] = conditionValue
          })

          return {
            ...alert,
            name: alert.title,
            ...conditionsObject,
          }
        }),
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, values } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(alerts.update, id, values)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: "L'alerte a été modifié avec succès",
    })

    yield put({ type: actions.GET_ALL })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id, pagination } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const response = yield call(alerts.remove, id)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })

    notification.success({
      message: 'Félicitation',
      description: "L'alerte a été suprimée avec succès",
    })

    yield put({
      type: actions.GET_ALL,
      payload: { pagination },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL, GET_ALL),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.DELETE, DELETE),
  ])
}
