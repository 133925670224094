import apiClient from 'services/axios'

export async function get() {
  return apiClient
    .get('/profile')
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function update(data) {
  return apiClient
    .put('/profile', {
      ...data,
      first_name: data.firstName,
      last_name: data.lastName,
    })
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function changePassword(data) {
  return apiClient
    .put('/password', data)
    .then(response => {
      if (response && response.status === 200) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
