import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as profile from 'services/profile'
import actions from './actions'

const getOccupation = (state, id) => {
  return state.occupations.list.find(item => item.id === id)
}

export function* GET() {
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const response = yield call(profile.get)

  if (response) {
    const { id, first_name: firstName, last_name: lastName, email, occupation, company } = response
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        id,
        firstName,
        lastName,
        email,
        occupation: yield select(getOccupation, occupation.id),
        company,
      },
    })
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE({ payload }) {
  const { values } = payload

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(profile.update, values)

  if (success) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })

    yield history.push('/profil')

    notification.success({
      message: 'Succesful updated',
      description: 'You have successfully updated your profile!',
    })
  }
  if (!success) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* CHANGE_PASSWORD({ payload }) {
  const { values } = payload

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(profile.changePassword, values)

  if (success) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })

    yield history.push('/profil')

    notification.success({
      message: 'Succesful updated',
      description: 'You have successfully updated your password!',
    })
  }
  if (!success) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET, GET),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.CHANGE_PASSWORD, CHANGE_PASSWORD),
  ])
}
