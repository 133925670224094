const actions = {
  SET_STATE: 'jobStates/SET_STATE',
  GET_ALL: 'jobStates/GET_ALL',
  // GET: 'jobStates/GET',
  // ADD: 'jobStates/ADD',
  // UPDATE: 'jobStates/UPDATE',
  // DELETE: 'jobStates/DELETE',
}

export default actions
