import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as categories from 'services/categories'
import actions from './actions'

export function* GET_ALL() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      list: null,
    },
  })

  const response = yield call(categories.getAll, 'salary_range')

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        list: response,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([GET_ALL(), takeEvery(actions.GET_ALL, GET_ALL)])
}
