const actions = {
  SET_STATE: 'industries/SET_STATE',
  GET_ALL: 'industries/GET_ALL',
  // GET: 'industries/GET',
  // ADD: 'industries/ADD',
  // UPDATE: 'industries/UPDATE',
  // DELETE: 'industries/DELETE',
}

export default actions
