import apiClient from 'services/axios'

export async function getAll(limit, offset) {
  const url = `/alerts?limit=${limit}&offset=${offset}`

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return {
          list: response.data,
          total: response.headers['content-range']?.split('/')[1],
        }
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function update(id, data) {
  return apiClient
    .put(`/alerts/${id}`, {
      title: data.name,
      query: {
        conditions: [
          {
            field: 'title',
            operator: 'like',
            value: data.title,
          },
          {
            field: 'jobs_locations.city',
            operator: 'like',
            value: data.location,
          },
          {
            field: 'occupational_category',
            operator: 'in',
            value: data.occupations?.join(),
          },
          {
            field: 'employment_type',
            operator: 'in',
            value: data.employmentTypes?.join(),
          },
          {
            field: 'career_level',
            operator: 'in',
            value: data.careerLevels?.join(),
          },
          {
            field: 'jobs.industry',
            operator: 'in',
            value: data.industries?.join(),
          },
        ],
      },
    })
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function remove(id) {
  return apiClient
    .delete(`/alerts/${id}`)
    .then(response => {
      if (response && response.status === 202) {
        return true
      }
      return false
    })
    .catch(err => console.log(err))
}
