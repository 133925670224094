import apiClient from 'services/axios'

// eslint-disable-next-line import/prefer-default-export
export async function getAll(extension) {
  return apiClient
    .get(`/categories?extension=${extension}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
