const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  FORGOT_PASSWORD: 'user/FORGOT_PASSWORD',
  VERIFY_TOKEN: 'user/VERIFY_TOKEN',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  COMPLETE_REGISTRATION: 'user/COMPLETE_REGISTRATION',
}

export default actions
